import axios from '@/libs/axios';

export default {
  /**
   * Send request for add like to post
   * @returns {Object}
   */
  async getSecretPaymentIntent(data) {
    const res = await axios.post('stripe/create', data);
    return res;
  },

  /**
   * Send request for add like to post
   * @returns {Object}
   */
  async getSecretSubscriptionPaymentIntent(data) {
    const res = await axios.post('stripe/subscribe', data);
    return res;
  },

  /**
   * Send request for get stripe info
   * @returns {Object}
   */
  async getInfo(data) {
    const res = await axios.post('stripe/info', data);
    return res;
  },

  /**
   * Send request for remove like from post
   * @returns {Object}
   */
  async removeLike(id) {
    const res = await axios.delete(`likes/posts/${id}`);
    return res;
  },
};
