<template>
  <div>
    <div class="press-banner">
      <div class="container">
        <h2 class="press-banner__title">Press</h2>
        <router-link class="press-banner__back" :to="{ name: 'PressPage' }">
          Back to all articles
        </router-link>
      </div>
    </div>
    <div v-if="pressSingle.id" class="press-article-full">
      <div class="container">
        <h1 class="press-article-full__title">{{pressSingle.title}}</h1>
        <div class="press-article-full__data">
          {{moment(pressSingle.created_at).format('Do MMMM YYYY')}}
        </div>
        <div class="press-article-full__text">
          <p>{{pressSingle.description}}</p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ContentService from '@/services/ContentService';
import moment from 'moment';

export default {
  name: 'PressCardSingle',
  components: {},
  mixins: [],
  props: {},
  data() {
    return {
      pressSingle: {},
    };
  },
  created() {
    this.getPressSingle().then((res) => {
      this.pressSingle = res;
    });
  },
  computed: {},
  methods: {
    async getPressSingle() {
      let res = null;

      try {
        res = await ContentService.getPressSingle(this.$route.params.id);
        return res.data;
      } catch (e) {
        return this.press;
      }
    },
    moment(date = '') {
      const arr = date.split('.');
      return moment(`${arr[1]}-${arr[0]}-${arr[2]}`, 'MM-DD-YYYY');
    },
  },
  mounted() {
    document.body.classList.remove('page-layout');
  },
};
</script>
<style lang="scss" scoped>
</style>
