<template>
  <div class="chat-user__item" :class="{active: active}" @click="OnSelectFriend">
    <span v-if="unreadMessagesCount"
      class="message_badge"
      :class="getClassBadgeLength(unreadMessagesCount)"
    >{{unreadMessagesCount}}</span>
    <div class="chat-user__avatar">
      <img v-if="friend.avatar" :src="friend.avatar" alt="">
      <img v-else src="@/assets/images/avatar.svg" alt="">
    </div>
    <div class="chat-user__name">{{friend.name}}</div>
  </div>
</template>
<script>
import badge from '@/mixins/badge';

export default {
  name: 'CardFriend',
  components: {},
  mixins: [badge],
  props: {
    friend: {
      type: Object,
      required: true,
    },
    active: {
      type: Boolean,
      required: false,
    },
    unreadMessagesCount: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {};
  },
  methods: {
    OnSelectFriend() {
      this.$emit('selectFriend', this.friend.id);
    },
  },
};
</script>
<style lang="scss">
.chat__sidebar {
  padding-top: 10px;
}
</style>

<style lang="scss" scoped>
.chat-user__item {
  position: relative;
}

.message_badge {
  &.two {
    width: 30px;
    border-radius: 40%;
  }

  &.three {
    width: 40px;
    border-radius: 40%;
  }

  display: flex;
  align-items: center;
  justify-content: center;

  width: 20px;
  height: 20px;

  position: absolute;
  top: -10px;
  right: -10px;

  background-color: red;
  border-radius: 50%;

  z-index: 1;

  color: #fff;
  font-size: 12px;
  font-weight: 600;

  // @media (max-width: 992px) {
  //   right: -20px;
  // }
}
</style>
