<template>
  <div :class="'dialog-' + type">
    <div :class="'dialog-' + type + '__item'">
      <div :class="'dialog-' + type + '__avatar'">
        <img v-if="message.avatar" :src="message.avatar" alt="avatar">
        <img v-else src="@/assets/images/avatar.svg" alt="avatar">
      </div>
      <div class="dialog__date">{{message.date}}</div>
      <div v-html="message.text" class="dialog__text"></div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'CardDialog',
  components: {},
  props: {
    message: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {};
  },
  computed: {
    type() {
      return !this.message.isFriend ? 'answer' : 'question';
    },
  },
  methods: {},
};
</script>
<style lang="scss" scoped>
</style>
