var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"contact-banner",attrs:{"id":"scrollTo"}},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"contact-banner__inner"},[_vm._m(0),_c('div',{staticClass:"contact-banner__form"},[_c('div',{staticClass:"form-block"},[_c('div',{staticClass:"form-block__body"},[(_vm.text && _vm.alert)?_c('div',{staticClass:"container_alert"},[(_vm.text)?_c('Alert',{attrs:{"variant":_vm.variant}},[_vm._v(_vm._s(_vm.text))]):_vm._e()],1):_vm._e(),_c('ValidationObserver',{ref:"form",attrs:{"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.sendForm)}}},[_c('ValidationProvider',{attrs:{"name":"Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var failed = ref.failed;
return [_c('div',{staticClass:"form-row",class:{ 'has-error': failed }},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.name),expression:"form.name"}],staticClass:"form-control",attrs:{"type":"text","placeholder":"Full Name","required":""},domProps:{"value":(_vm.form.name)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "name", $event.target.value)}}}),_c('span',{staticClass:"help-message"},[_vm._v(_vm._s(errors[0]))])])]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"Email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var failed = ref.failed;
return [_c('div',{staticClass:"form-row",class:{ 'has-error': failed }},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.email),expression:"form.email"}],staticClass:"form-control",attrs:{"type":"email","placeholder":"Email","required":""},domProps:{"value":(_vm.form.email)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "email", $event.target.value)}}}),_c('span',{staticClass:"help-message"},[_vm._v(_vm._s(errors[0]))])])]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"Subject","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var failed = ref.failed;
return [_c('div',{staticClass:"form-row",class:{ 'has-error': failed }},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.subject),expression:"form.subject"}],staticClass:"form-control",attrs:{"type":"text","placeholder":"Message Subject","required":""},domProps:{"value":(_vm.form.subject)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "subject", $event.target.value)}}}),_c('span',{staticClass:"help-message"},[_vm._v(_vm._s(errors[0]))])])]}}],null,true)}),_c('div',{staticClass:"form-row"},[_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.body),expression:"form.body"}],staticClass:"form-control",attrs:{"name":"","id":"","cols":"30","rows":"10","placeholder":"Write us something...","required":""},domProps:{"value":(_vm.form.body)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "body", $event.target.value)}}})]),_c('div',{staticClass:"btns-group--right"},[_c('button',{staticClass:"btn btn-primary",attrs:{"type":"submit"}},[_vm._v(" Submit ")])])],1)]}}])})],1)])])])])]),_vm._m(1)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"contact-banner__content"},[_c('div',{staticClass:"contact-banner__content-wrapper"},[_c('h2',{staticClass:"contact-banner__title"},[_vm._v("Contact us!")]),_c('p',{staticClass:"contact-banner__text"},[_vm._v(" We would be happy to hear more from you! Tell us what you feel, give us feedback, or suggest new ideas! Just fill out the form on the right or write us an email. ")]),_c('a',{staticClass:"contact-banner__btn",attrs:{"href":"mailto:info@wethink.eu"}},[_vm._v("info@wethink.eu")])])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"contact-soc"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"contact-soc__head"},[_vm._v(" Follow our Social Media ")]),_c('div',{staticClass:"contact-soc__inner"},[_c('div',{staticClass:"contact-soc__content"},[_c('a',{staticClass:"contact-soc__item-1 icon-fb-w",attrs:{"href":"https://www.facebook.com/weThink.Platform/"}},[_vm._v(" weThink. Platform ")]),_c('a',{staticClass:"contact-soc__item-2 icon-tw-w",attrs:{"href":"https://twitter.com/weTHINK_europe"}},[_vm._v(" weTHINK_europe ")]),_c('a',{staticClass:"contact-soc__item-3 icon-in-w",attrs:{"href":"https://www.instagram.com/wethink.eu/"}},[_vm._v(" thinkplatform ")])])])])])}]

export { render, staticRenderFns }