<template>
  <div class="team__item">
    <div v-if="member.social" class="team__soc">
      <a :href="member.social" target="_blank">
        <img src="@/assets/images/in-icon.svg" alt="Linkedin">
      </a>
    </div>
    <TeamIcon v-if="getRandomKey(0, 1)" :number="getRandomKey(1,5)"/>
    <div class="team__avatar">
      <img v-if="!member.image" src="@/assets/images/bg-team-no-photo.png" alt="">
      <img v-else :src="member.image" alt="">
    </div>
    <div class="team__title">{{member.name}}</div>
    <div class="team__position">{{member.title}}</div>
  </div>
</template>
<script>
import TeamIcon from '@/views/pages/About/TeamIcon.vue';

import random from '@/mixins/random';

export default {
  name: 'About',
  components: {
    TeamIcon,
  },
  mixins: [random],
  props: {
    member: {
      type: Object,
      reqired: true,
    },
  },
  data() {
    return {
      teams: [],
    };
  },
  created() {},
  computed: {},
  methods: {},
  mounted() {},
  updated() {},
};
</script>
<style lang="scss" scoped>
</style>
