<template>
  <form action="" id="card-ele">
    <Alert :variant="variant" v-if="text">{{ text }}</Alert>
    <div class="form-row">
      <div id="card-element" class="card_element">
        <!-- A Stripe Element will be inserted here. -->
      </div>
      <!-- Used to display form errors. -->
      <div id="card-errors" role="alert"></div>
    </div>
    <div class="form-row">
      <input
        v-model="cardCur.email"
        class="form-control"
        type="email"
        placeholder="Email"
      >
    </div>
    <div class="form-row">
      <textarea
        v-model="cardCur.description"
        class="form-control"
        name=""
        id=""
        cols="30"
        rows="5"
        placeholder="Leave a comment (optional)"
      ></textarea>
    </div>
    <div class="btns-group--right">
      <a class="btn btn-primary--invert" href="#" @click.prevent="$emit('prevStep')">Go Back</a>
      <a
        class="btn btn-primary"
        href="#"
        @click.prevent="$emit('donate', stripe, cardElement)"
      >
        Donate
      </a>
    </div>
  </form>
</template>
<script>
import { loadStripe } from '@stripe/stripe-js';

import Alert from '@/components/Alert.vue';

import alert from '@/mixins/alert';

export default {
  name: 'FormStep3',
  components: { Alert },
  mixins: [alert],
  props: {
    card: {
      type: Object,
      default: null,
    },
    alertText: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      cardCur: this.card,
      stripe: null,
      elements: null,
      cardElement: null,
    };
  },
  created() {},
  computed: {},
  methods: {},
  async mounted() {
    this.stripe = await loadStripe(this.$stripePublicKey);
    this.elements = this.stripe.elements();
    const style = {
      base: {
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSmoothing: 'antialiased',
        fontSize: '14px',
        '::placeholder': {
          color: '#7e7c79',
        },
      },
      invalid: {
        color: '#fa755a',
        iconColor: '#fa755a',
      },
    };

    this.cardElement = this.elements.create('card', {
      style,
      hidePostalCode: true,
    });

    this.cardElement.mount('#card-element');
  },
  updated() {},
  watch: {
    alertText(text) {
      if (text) {
        this.$emit('clearAlertText');
        this.showAndHideAlertSuccess(`Error: ${text}`, 'danger');
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.card_element {
  min-height: 50px;
  padding: 15px 20px;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 15px;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  box-shadow: 0 0.125rem 0.25rem rgba(0,0,0,0.075);
}
</style>
