<template>
  <div>
    <div class="press-banner">
      <div class="container">
        <div class="press-banner__inner">
          <h2 class="press-banner__title">Press</h2>
          <div class="press-banner__content">
            <p class="press-banner__text" v-html="content.block[0]"></p>
            <a class="contact-banner__btn" href="mailto:press@wethink.eu">press@wethink.eu</a>
          </div>
        </div>
      </div>
    </div>

    <div class="press-article">
      <div class="container">
        <div class="press-article__head">Published Articles</div>
        <div class="press-article__list">
          <PressCard
            v-for="pr in press"
            :key="pr.id + pr.created_at"
            :press="pr || {}"
          />
        </div>
        <Pagination v-if="pagination.pageCount > 1"
            :pagination="pagination"
            v-on:changepage="getPress"
            style="margin-bottom: 50px;"
          />
      </div>
    </div>
  </div>
</template>
<script>
import PressCard from '@/views/pages/Press/PressCard.vue';
import Pagination from '@/views/profile/MyGroups/Pagination.vue';

import content from '@/mixins/content';
import pagination from '@/mixins/pagination';

import ContentService from '@/services/ContentService';

export default {
  name: 'Press',
  components: { PressCard, Pagination },
  mixins: [content, pagination],
  data() {
    return {
      press: [],
    };
  },
  created() {
    this.getPress();
  },
  computed: {},
  methods: {
    async getPress() {
      let res = null;

      try {
        res = await ContentService.getPress({ ...this.$route.query, ...{ 'per-page': 1 } });

        this.setPagination(res.headers);
        this.press = res.data;
      } catch (e) {
        console.log(e);
      }
    },
  },
  mounted() {
    document.body.classList.remove('page-layout');
  },
  updated() {},
};
</script>
<style lang="scss" scoped>
.press-banner {
  padding-top: 20px;
}
</style>
