<template>
  <div>
    <div id="scrollTo" class="contact-banner">
      <div class="container">
        <div class="contact-banner__inner">
          <div class="contact-banner__content">
            <div class="contact-banner__content-wrapper">
              <h2 class="contact-banner__title">Contact us!</h2>
              <p class="contact-banner__text">
                We would be happy to hear more from you! Tell us what you feel, give us feedback,
                or suggest new ideas! Just fill out the form on the right or write us an email.
              </p>
              <a class="contact-banner__btn" href="mailto:info@wethink.eu">info@wethink.eu</a>
            </div>
          </div>
          <div class="contact-banner__form">
            <div class="form-block">
              <div class="form-block__body">
                <div v-if="text && alert" class="container_alert">
                  <Alert :variant="variant" v-if="text">{{ text }}</Alert>
                </div>
                <ValidationObserver slim v-slot="{ handleSubmit }" ref="form">
                  <form @submit.prevent="handleSubmit(sendForm)">
                    <ValidationProvider name="Name" rules="required" v-slot="{ errors, failed }">
                      <div class="form-row" :class="{ 'has-error': failed }">
                        <input
                          v-model="form.name"
                          class="form-control"
                          type="text"
                          placeholder="Full Name"
                          required
                        >
                        <span class="help-message">{{ errors[0] }}</span>
                      </div>
                    </ValidationProvider>
                    <ValidationProvider
                      name="Email"
                      rules="required|email"
                      v-slot="{ errors, failed }"
                    >
                      <div class="form-row" :class="{ 'has-error': failed }">
                        <input
                          v-model="form.email"
                          class="form-control"
                          type="email"
                          placeholder="Email"
                          required
                        >
                        <span class="help-message">{{ errors[0] }}</span>
                      </div>
                    </ValidationProvider>
                    <ValidationProvider name="Subject" rules="required" v-slot="{ errors, failed }">
                      <div class="form-row" :class="{ 'has-error': failed }">
                        <input
                          v-model="form.subject"
                          class="form-control"
                          type="text"
                          placeholder="Message Subject"
                          required
                        >
                        <span class="help-message">{{ errors[0] }}</span>
                      </div>
                    </ValidationProvider>
                    <div class="form-row">
                      <textarea
                        v-model="form.body"
                        class="form-control"
                        name=""
                        id=""
                        cols="30"
                        rows="10"
                        placeholder="Write us something..."
                        required
                      ></textarea>
                    </div>
                    <div class="btns-group--right">
                      <button class="btn btn-primary" type="submit">
                        Submit
                      </button>
                    </div>
                  </form>
                </ValidationObserver>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="contact-soc">
      <div class="container">
        <div class="contact-soc__head">
          Follow our Social Media
        </div>
        <div class="contact-soc__inner">
          <div class="contact-soc__content">
            <a class="contact-soc__item-1 icon-fb-w" href="https://www.facebook.com/weThink.Platform/">
              weThink. Platform
            </a>
            <a class="contact-soc__item-2 icon-tw-w" href="https://twitter.com/weTHINK_europe">
              weTHINK_europe
            </a>
            <a class="contact-soc__item-3 icon-in-w" href="https://www.instagram.com/wethink.eu/">
              thinkplatform
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Alert from '@/components/Alert.vue';

import ContentService from '@/services/ContentService';

import alert from '@/mixins/alert';

export default {
  name: 'About',
  components: { Alert },
  mixins: [alert],
  data() {
    return {
      form: {
        name: '',
        email: '',
        subject: '',
        body: '',
      },
    };
  },
  created() {},
  computed: {},
  methods: {
    async sendForm() {
      let res = null;
      try {
        res = await ContentService.sendQuestion(this.form);
        this.clearForm();
        this.scrollToTop();
        this.$refs.form.reset();
        this.showAndHideAlertSuccess(res.data.message, 'success');
      } catch (e) {
        this.scrollToTop();
        this.showAndHideAlertSuccess(e.response.data.message, 'danger');
      }
    },
    clearForm() {
      this.form = {
        name: '',
        email: '',
        subject: '',
        body: '',
      };
    },
    scrollToTop() {
      document.getElementById('scrollTo').scrollIntoView({ behavior: 'smooth' });
    },
  },
  mounted() {
    document.body.classList.remove('page-layout');
  },
  updated() {},
};
</script>
<style lang="scss" scoped>
.press-banner {
  padding-top: 20px;
}
</style>
