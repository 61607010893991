<template>
  <div>
    <div class="about-banner">
      <div class="container">
        <div class="about-banner__inner">
          <div class="about-banner__content">
            <h2 class="about-banner__title">Meet the Team</h2>
            <p class="about-banner__text" v-html="content.block[0]"></p>
          </div>
        </div>
      </div>
    </div>
    <div class="about-team">
      <div class="container">
        <div class="about-team__wrapper">
          <div v-for="(team, levelName) in teams" :key="levelName">
            <div
              v-if="levelName !== 'Top'"
              :class="'team__head decor-' + getRandomKey(1, 3)"
            >
              {{levelName}}
            </div>
            <div class="team__list">
              <AboutCard v-for="member in team" :key="member.id + member.name" :member="member"/>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import AboutCard from '@/views/pages/About/AboutCard.vue';

import ContentService from '@/services/ContentService';

import content from '@/mixins/content';
import random from '@/mixins/random';

export default {
  name: 'About',
  components: {
    AboutCard,
  },
  mixins: [content, random],
  data() {
    return {
      teams: [],
    };
  },
  created() {
    this.getAbout().then((res) => {
      this.teams = res;
    });
  },
  computed: {},
  methods: {
    async getAbout() {
      let res = null;

      try {
        res = await ContentService.getAbout();
        return res.data;
      } catch (e) {
        return this.about;
      }
    },
  },
  mounted() {
    document.body.classList.remove('page-layout');
  },
  updated() {},
};
</script>
<style lang="scss" scoped>
.press-banner {
  padding-top: 20px;
}
</style>
