<template>
  <div class="press-article-full">
    <div class="container">

      <p v-html="content.block[0]"></p>

      <a id="policy"></a>
      <h1 class="press-article-full__title">{{content.title}}</h1>
      <div class="press-article-full__text">
        <p v-html="content.block[1]"></p>
      </div>

      <a id="cookie"></a>
      <h1 class="press-article-full__title">Cookie Policy</h1>
      <div class="press-article-full__text">
        <p v-html="content.block[2]"></p>
      </div>

      <a id="legal"></a>
      <h1 class="press-article-full__title">Legal Guidelines</h1>
      <div class="press-article-full__text">
        <p v-html="content.block[3]"></p>
      </div>

      <a id="copyright"></a>
      <h1 class="press-article-full__title">Copryright statement</h1>
      <div class="press-article-full__text">
        <p v-html="content.block[4]"></p>
      </div>

    </div>
  </div>
</template>
<script>
import content from '@/mixins/content';

export default {
  name: 'PrivacyPage',
  components: {},
  mixins: [content],
  props: {},
  data() {
    return {};
  },
  created() {},
  computed: {},
  methods: {},
  mounted() {
    document.body.classList.remove('page-layout');
  },
};
</script>
<style lang="scss" scoped>
</style>
