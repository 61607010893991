import axios from '@/libs/axios';

export default {
  /**
   * Send request for get messages by friend id
   * @param {Number} id
   * @returns {Object}
   */
  async get(id, query = null) {
    const res = await axios.get(`messages/users/${id}`, { params: query });
    return res;
  },

  /**
   * Send request for send message to friend
   * @param {Number} id
   * @returns {Object}
   */
  async send(data) {
    const res = await axios.post('messages/send', data);
    return res;
  },
};
