<template>
  <div :class="'team__icon-' + number">
    <img :src="require('@/assets/images/icon-team-' + number + '.svg')" alt="">
  </div>
</template>
<script>
import random from '@/mixins/random';

export default {
  name: 'About',
  components: {},
  mixins: [random],
  props: {
    number: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {};
  },
  created() {},
  computed: {},
  methods: {},
  mounted() {},
  updated() {},
};
</script>
<style lang="scss" scoped>
</style>
