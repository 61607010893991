<template>
  <div class="press-article-full">
    <div class="container">
      <h1 class="press-article-full__title">{{content.title}}</h1>
      <div class="press-article-full__text">
        <p v-html="content.block[0]"></p>
      </div>
    </div>
  </div>
</template>
<script>
import content from '@/mixins/content';

export default {
  name: 'LegalNoticePage',
  components: {},
  mixins: [content],
  props: {},
  data() {
    return {};
  },
  created() {},
  computed: {},
  methods: {},
  mounted() {
    document.body.classList.remove('page-layout');
  },
};
</script>
<style lang="scss" scoped>
</style>
