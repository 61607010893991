<template>
  <div class="stepper">
    <div class="stepper__wrapper">
      <div class="stepper__content">
          <ul class="stepbar">
            <li :class="{active: step === 1}"></li>
            <li :class="{active: step === 2}"></li>
            <li :class="{active: step === 3}"></li>
          </ul>
          <div class="stepper__box">
          <FormStep1
            v-if="step === 1"
            :amount="form.amount"
            @changeAmount="form.amount = $event"
            @nextStep="step = 2"
          />
          <FormStep2
            v-else-if="step === 2"
            :firstName="form.first_name"
            :lastName="form.last_name"
            :email="form.email"
            :description="form.text"
            @changeFirstName="form.first_name = $event"
            @changeLastName="form.last_name = $event"
            @changeEmail="form.email = $event"
            @changeText="form.text = $event"
            @prevStep="step = 1"
            @nextStep="step = 3"
          />
          <FormStep3
            v-else-if="step === 3"
            :card="form.card"
            :alertText="text"
            @changeAmount="form.amount = $event"
            @prevStep="step = 2"
            @donate="donate"
            @clearAlertText="text = ''"
          />
          <FormStepSuccess
            v-else-if="step === 4"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import FormStep1 from '@/views/pages/Donate/Components/FormStep1.vue';
import FormStep2 from '@/views/pages/Donate/Components/FormStep2.vue';
import FormStep3 from '@/views/pages/Donate/Components/FormStep3.vue';
import FormStepSuccess from '@/views/pages/Donate/Components/FormStepSuccess.vue';

import StripeService from '@/services/StripeService';

export default {
  name: 'Donate',
  components: {
    FormStep1,
    FormStep2,
    FormStep3,
    FormStepSuccess,
  },
  mixins: [],
  data() {
    return {
      step: 1,
      text: '',
      form: {
        amount: '',
        first_name: '',
        last_name: '',
        email: '',
        text: '',
        card: {
          number: '',
          expired: '',
          cvv: '',
          email: '',
          description: '',
        },
      },
    };
  },
  created() {},
  computed: {},
  methods: {
    async getSecretIntendKey() {
      let res = null;

      try {
        res = await StripeService.getSecretPaymentIntent({ amount: this.form.amount * 100 });
        return res;
      } catch (e) {
        console.log(e);
        return {};
      }
    },
    async donate(stripe, card) {
      const requestIntend = await this.getSecretIntendKey();

      stripe
        .confirmCardPayment(requestIntend.data.client_secret, {
          payment_method: {
            card,
            billing_details: {
              name: `${this.form.first_name} ${this.form.last_name}`,
              email: this.form.email,
            },
            metadata: {
              text: this.form.text,
              pay_description: this.form.card.description,
            },
          },
          receipt_email: this.form.card.email,
        })
        .then((result) => {
          if (result.error) {
            this.text = result.error.message;
          } else {
            this.step = 4;
          }
        });
    },
  },
  mounted() {
    document.body.classList.remove('page-layout');
  },
  updated() {},
};
</script>
<style lang="scss" scoped>
</style>
