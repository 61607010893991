<template>
<ValidationObserver ref="form">
  <form v-on:submit.prevent>
    <Alert :variant="variant" v-if="text">{{ text }}</Alert>
    <div class="form-row donate-step-1">
      <div class="form-control__cur"><span>EUR €</span></div>
      <ValidationProvider
        :rules="{ regex: /^[0-9]*\.?[0-9]+$/, required: true }"
        v-slot="{ errors }"
      >
        <input
          v-model="amountCur"
          class="form-control"
          type="text"
          step="0.01"
          min=0
          name="amount"
          placeholder="Amount"
          @keyup.enter.prevent="checkAmount"
          @input="$emit('changeAmount', amountCur)"
        >
        <span style="color: red;">{{ errors[0] }}</span>
      </ValidationProvider>
    </div>
    <div class="btns-group--right">
      <a class="btn btn-primary" href="#" @click.prevent="checkAmount">Next</a>
    </div>
  </form>
</ValidationObserver>
</template>
<script>
import Alert from '@/components/Alert.vue';

import alert from '@/mixins/alert';

export default {
  name: 'FormStep1',
  components: { Alert },
  mixins: [alert],
  props: {
    amount: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      amountCur: this.amount,
    };
  },
  created() {},
  computed: {},
  methods: {
    checkAmount() {
      // debugger;

      this.$refs.form.validate().then((success) => {
        // debugger;
        if (!success) {
          return;
        }

        this.$emit('nextStep');

        // Resetting Values
        // this.firstName = this.lastName = this.email = '';

        // Wait until the models are updated in the UI
        // this.$nextTick(() => {
        //   this.$refs.form.reset();
        // });
      });

      // if (!this.amountCur) {
      //   this.showAndHideAlertSuccess('Error: Please enter Amount', 'danger');
      // } else if (this.amountCur <= 0) {
      //   this.showAndHideAlertSuccess('Error: Please enter Amount more then 0', 'danger');
      // } else {
      //   this.$emit('nextStep');
      // }
    },
  },
  mounted() {},
  updated() {},
};
</script>
<style lang="scss" scoped>
</style>
