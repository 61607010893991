<template>
<form action="">
  <div class="form-row--success">
    Success!
  </div>
  <div class="donation-end__img">
    <img src="@/assets/images/donation-success.svg" alt="">
  </div>
  <div class="donation-end__text">
    {{text}}
  </div>
</form>
</template>
<script>
export default {
  name: 'FormStepSuccess',
  components: {},
  mixins: [],
  props: {
    text: {
      type: String,
      default: 'Thank you for donating!',
    },
  },
  data() {
    return {};
  },
  created() {},
  computed: {},
  methods: {},
  mounted() {},
  updated() {},
};
</script>
<style lang="scss" scoped>
</style>
