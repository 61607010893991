<template>
  <div class="press-article__item">
    <h2 class="press-article__title">{{press.title}}</h2>
    <div class="press-article__text">{{press.short}}</div>
    <router-link
      class="press-article__more"
      :to="{ name: 'PressPageSingle', params: { id: press.id } }">
      Read more...
    </router-link>
    <div class="press-article__data">
      {{moment(press.created_at).format('Do MMMM YYYY')}}
    </div>
  </div>
</template>
<script>
import moment from 'moment';
import content from '@/mixins/content';

export default {
  name: 'PressCard',
  components: {},
  mixins: [content],
  props: {
    press: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {};
  },
  created() {},
  computed: {},
  methods: {
    moment(date = '') {
      const arr = date.split('.');
      return moment(`${arr[1]}-${arr[0]}-${arr[2]}`, 'MM-DD-YYYY');
    },
  },
  mounted() {
    document.body.classList.remove('page-layout');
  },
  updated() {},
};
</script>
<style lang="scss" scoped>
</style>
