<template>
  <form action="">
    <Alert :variant="variant" v-if="text">{{ text }}</Alert>
    <div class="form-row">
      <input
        v-model="form.first_name"
        class="form-control"
        type="text"
        placeholder="First Name"
        @input="$emit('changeFirstName', form.first_name)"
      >
    </div>
    <div class="form-row">
      <input
        v-model="form.last_name"
        class="form-control"
        type="text"
        placeholder="Last Name"
        @input="$emit('changeLastName', form.last_name)"
      >
    </div>
    <div class="form-row">
      <input
        v-model="form.email"
        class="form-control"
        type="email"
        placeholder="Email"
        @input="$emit('changeEmail', form.email)"
      >
    </div>
    <div class="form-row">
      <input
        v-model="form.text"
        class="form-control"
        type="text"
        placeholder="Institution (optional)"
        @input="$emit('changeText', form.text)"
      >
    </div>
    <div class="btns-group--right">
      <a class="btn btn-primary--invert" href="#" @click.prevent="$emit('prevStep')">Go Back</a>
      <a class="btn btn-primary" href="#" @click.prevent="checkForm">Next</a>
    </div>
  </form>
</template>
<script>
import Alert from '@/components/Alert.vue';

import alert from '@/mixins/alert';

export default {
  name: 'FormStep2',
  components: { Alert },
  mixins: [alert],
  props: {
    firstName: {
      type: String,
      default: '',
    },
    lastName: {
      type: String,
      default: '',
    },
    email: {
      type: String,
      default: '',
    },
    description: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      form: {
        first_name: this.firstName,
        last_name: this.lastName,
        email: this.email,
        text: this.text,
      },
    };
  },
  created() {},
  computed: {},
  methods: {
    checkForm() {
      if (!this.form.first_name) {
        this.showAndHideAlertSuccess('Error: Please enter your First name', 'danger');
      } else if (!this.form.last_name) {
        this.showAndHideAlertSuccess('Error: Please enter your Last name', 'danger');
      } else if (!this.form.email) {
        this.showAndHideAlertSuccess('Error: Please enter your Email', 'danger');
      } else if (!this.validateEmail(this.form.email)) {
        this.showAndHideAlertSuccess('Error: Please enter correct email', 'danger');
      } else {
        this.$emit('nextStep');
      }
    },
    validateEmail(email) {
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(email).toLowerCase());
    },
  },
  mounted() {},
  updated() {},
};
</script>
<style lang="scss" scoped>
</style>
