<template>
  <div class="chat__wrapper">
    <div class="chat__banner">
      <div class="container">
        <h4 class="chat__banner-title">Messages</h4>
      </div>
    </div>

    <div class="chat__content">
      <div class="container">
        <div class="chat__inner">
          <div class="chat__sidebar scrollbar">
            <CardFriend v-for="friend in sortedFriends"
              :key="friend.id"
              :friend="friend"
              :active="friend.id == friendID"
              :unreadMessagesCount="+unreadFriends[friend.id] || 0"
              @selectFriend="onGetMessages"
            />
          </div>
          <div class="chat__container">
            <div id="chat__area" class="chat__area">
              <div class="chat__header">
                <div class="chat__avatar">
                  <img v-if="selectedFriend.avatar" :src="selectedFriend.avatar" alt="">
                  <img v-else src="@/assets/images/avatar.svg" alt="">
                </div>
                <div class="chat__name">
                  {{selectedFriend.name}}
                </div>
              </div>
              <div
                id="chat_scrollbar"
                class="chat__body scrollbar"
              >
                <a
                  v-if="isLoadMore"
                  href="#"
                  class="dialog-more"
                  @click.prevent="onLoadMore"
                >Load more messages</a>
                <div v-for="message in messages"
                  :key="message.created_at + message.text"
                  class="container_dialog"
                >
                  <CardDialog
                    :message="parseMessage(message)"
                  />
                </div>
              </div>
              <div class="chat__bottom">
                <textarea
                  v-model="messageText"
                  @keyup.ctrl.enter="onSendMessage"
                  placeholder="Write something"
                ></textarea>
                <button class="chat-btn" @click.prevent="onSendMessage">SEND</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import FriendService from '@/services/FriendService';
import MessagesService from '@/services/MessagesService';
import CardFriend from '@/views/pages/Messages/Components/CardFriend.vue';
import CardDialog from '@/views/pages/Messages/Components/CardDialog.vue';
import random from '@/mixins/random';
import exception from '@/mixins/exception';
import pagination from '@/mixins/pagination';
import content from '@/mixins/content';
import websocket from '@/mixins/websocket';

import moment from 'moment';

export default {
  name: 'MessagesPage',
  components: {
    CardFriend,
    CardDialog,
  },
  mixins: [random, exception, pagination, content, websocket],
  data() {
    return {
      friends: [],
      messages: [],
      messageAdded: false,
      friendID: undefined,
      messageText: '',
      unreadFriends: [],
    };
  },
  async created() {
    await this.getFriends();

    const id = this.$route.params.id || this.friends[0]?.id;

    this.setPaginationPerPage(10);

    if (id) await this.onGetMessages(this.$route.params.id || this.friends[0].id);
    this.subscribeToChannel('messages', this.setMessagesCountFromWebsocket);
  },
  computed: {
    me() {
      return this.$store.getters.getMe;
    },
    isLoadMore() {
      return this.pagination.pageCount > 1
        && +this.pagination.pageCount !== +this.pagination.pageCurrent;
    },
    selectedFriend() {
      return this.friends.find((friend) => friend.id === this.friendID) || {};
    },
    sortedFriends() {
      return this.friends
        .map((friend) => ({ ...friend, unreadCount: +this.unreadFriends[friend.id] || 0 }))
        .sort((first, second) => second.unreadCount - first.unreadCount);
    },
  },
  methods: {
    async getFriends() {
      let res = null;
      const data = {
        filter: 'all',
        page: this.$route.query.page || 1,
        'per-page': 100,
      };
      this.clearMessageText();

      try {
        res = await FriendService.get(data);
        this.friends = res.data.filter((friend) => friend.status === 'approved');
      } catch (e) {
        this.friends = [];
        this.handleException(e);
      }
    },
    async onGetMessages(friendID) {
      let res = null;
      this.setFriendID(friendID);
      const query = {
        page: 1,
        'per-page': this.pagination.perPage,
      };

      try {
        res = await MessagesService.get(friendID, query);
        this.setPagination(res.headers);
        this.messages = res.data.reverse();
        this.messageAdded = true;
      } catch (e) {
        this.messages = [];
        this.handleException(e);
      }
    },
    async onSendMessage() {
      let res = null;
      const messageText = this.messageText.replaceAll('\n', '<br>').replaceAll(' ', '&nbsp;');
      const data = {
        friend_id: this.friendID,
        text: messageText,
      };
      document.body.style.cursor = 'wait';

      try {
        res = await MessagesService.send(data);

        if (res.data.status === 200) {
          this.messageText = messageText;
          this.putMessage();
          this.clearMessageText();
          this.messageAdded = true;
          // window.scrollTo(0, document.querySelector('#chat_scrollbar).scrollHeight);
          // document.getElementById('chat_scrollbar').scrollTop
          // = document.getElementById('chat_scrollbar').scrollHeight + 1000;
          // document.getElementById('chat__area')
          // .scrollIntoView({ block: 'start', behavior: 'smooth' });
        }

        document.body.style.cursor = 'auto';
      } catch (e) {
        this.handleException(e);
      }
    },
    async onLoadMore() {
      let res = null;
      const query = {
        page: +this.pagination.pageCurrent + 1,
        'per-page': this.pagination.perPage,
      };

      try {
        res = await MessagesService.get(this.friendID, query);

        this.setPagination(res.headers);
        this.messages = [...res.data.reverse(), ...this.messages];
      } catch (e) {
        this.messages = [];
        this.handleException(e);
      }
    },
    parseMessage(message) {
      const friendTemp = this.friends.find((friend) => friend.id === this.friendID);
      let avatar = null;

      if (this.me.id === message.user_id) {
        avatar = this.me.avatar;
      } else if (friendTemp.avatar) {
        avatar = friendTemp.avatar;
      } else if (message.avatar) {
        avatar = message.avatar;
      }

      return {
        date: message.created_at || message.date,
        avatar,
        text: message.text,
        isFriend: this.me.id !== message.user_id,
      };
    },
    setFriendID(id) {
      this.friendID = id;
    },
    putMessage() {
      this.messages.push({
        created_at: this.moment(Date.now()).format('DD. MMM YYYY [at] kk:mm'),
        friend_id: this.friendID,
        read_at: null,
        text: this.messageText,
        user_id: this.me.id,
      });
    },
    clearMessageText() {
      this.messageText = '';
    },
    setPaginationPerPage(count) {
      this.pagination.perPage = count;
    },
    moment(date) {
      return moment(date);
    },
    setMessagesCountFromWebsocket(event) {
      this.unreadFriends = JSON.parse(event.data).message.messages;
      const message = JSON.parse(event.data).update;

      if (!message) return;

      /* eslint-disable no-unused-expressions */
      (this.selectedFriend.id === message.user_id) && this.messages.push(message);
      setTimeout(() => {
        document.getElementById('chat_scrollbar').scrollTop = document.getElementById('chat_scrollbar').scrollHeight;
      }, 0);
    },
  },
  mounted() {
    document.getElementById('chat_scrollbar').scrollTop = document.getElementById('chat_scrollbar').scrollHeight;
  },
  updated() {
    if (this.messageAdded) {
      document.getElementById('chat_scrollbar').scrollTop = document.getElementById('chat_scrollbar').scrollHeight;
      this.messageAdded = false;
    }
  },
};
</script>
<style lang="scss" scoped>
.container_dialog {
  margin-bottom: 80px;
}

.container_dialog:last-child {
    margin-bottom: 0;
}

.list-complete-item {
  transition: all 1s;
  display: inline-block;
  margin-right: 10px;
}
.list-complete-enter, .list-complete-leave-to
/* .list-complete-leave-active до версии 2.1.8 */ {
  opacity: 0;
  transform: translateY(30px);
}
.list-complete-leave-active {
  position: absolute;
}
</style>
